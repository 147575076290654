import clsx from 'clsx';
import { useAtom } from 'jotai';
import React, { startTransition, useState } from 'react';
import { Scrollable } from '../../../components/Scrollable/Scrollable.tsx';
import { updateBookingUrlStateAtom, reloadResaurantKey } from '../lib/state.ts';

import { useRestaurant } from '../lib/state.ts';

import User_dark from '../../../assets/images/User_dark.svg?react';
import User_light from '../../../assets/images/User_light.svg?react';
import { MetaAvailability } from '../../../lib/types.ts';

import styles from './ScrollableItem.module.css';

export const ReservationTimes: React.FC = () => {
  const { meta } = useRestaurant();

  if (!meta) {
    return <></>;
  }

  return (
    <Scrollable>
      {meta.availability.map((time: MetaAvailability) => (
        <ReservationTime key={time.uid} time={time} />
      ))}
    </Scrollable>
  );
};

export const ReservationTimesLoader: React.FC = () => {
  return (
    <Scrollable>
      <div className={clsx(styles.scrollableItem, styles.loading)}>
        <div className={styles.title} />
        <div className={styles.text} />
        <div className={styles.text} />
      </div>
      <div className={clsx(styles.scrollableItem, styles.loading)}>
        <div className={styles.title} />
        <div className={styles.text} />
        <div className={styles.text} />
      </div>
      <div className={clsx(styles.scrollableItem, styles.loading)}>
        <div className={styles.title} />
        <div className={styles.text} />
        <div className={styles.text} />
      </div>
    </Scrollable>
  );
};

interface Props {
  time: MetaAvailability;
}

const ReservationTime: React.FC<Props> = ({ time }) => {
  const [temp, setTemp] = useAtom(updateBookingUrlStateAtom);
  const [hover, setHover] = useState<boolean>(false);
  const current_chosen = temp.availability?.uid === time.uid;
  const active = current_chosen || hover;

  const onEnter = () => setHover(true);
  const onLeave = () => setHover(false);
  const onClick = () => {
    if (current_chosen) {
      return;
    }

    reloadResaurantKey();

    startTransition(() => {
      setTemp({
        availability: time,
        option: time.options.length === 1 ? time.options[0] : undefined,
      });
    });
  };

  const className = clsx(styles.scrollableItem, {
    [styles.selected]: current_chosen,
    [styles.hover]: hover,
  });

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <div className={styles.title}>{time.datetime_label}</div>
      <div className={styles.text}>{time.price__currency}/person 1</div>
      <div className={styles.text}>
        {active ? <User_light /> : <User_dark />}
        <span>
          {time.min_seats! >= time.max_seats!
            ? time.min_seats
            : `${time.min_seats}-${time.max_seats}`}
        </span>
      </div>
    </div>
  );
};
