import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import RootLoader from '../../components/RootLoader/RootLoader.tsx';
import Config from '../../lib/Config.ts';
import { useWidgetState, useResetState, AuthAtom } from './lib/state.ts';

import { WIDGET_STATE_VERSION } from './lib/types.ts';

let CHECKED = false;

const Widget: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [widget, setState] = useWidgetState();
  const [auth, setAuth] = useAtom(AuthAtom);
  const { pathname } = useLocation();
  const resetState = useResetState();

  useEffect(() => {
    // Scroll to the top of the page any time the path changes
    document.getElementById('root')!.scrollIntoView();
  }, [pathname]);

  useEffect(() => {
    if (widget.date && !CHECKED) {
      CHECKED = true;
      const version: string | null = widget.version ?? null;
      if (version !== WIDGET_STATE_VERSION) {
        console.info('Starting Over due to Newer Data Version');
        onClear();
      } else if (dayjs().diff(widget.expires) > 0) {
        console.info('Starting Over due to Expiration');
        onClear();
      }
    }
  }, [widget.date]);

  const onLogout = () => {
    Sentry.setUser(null);

    setAuth(null);
    setState({
      phone_confirmed: null,
    });
    navigate(`/restaurant/${id}`);
  };

  const onClear = () => {
    resetState();
    navigate(`/restaurant/${id}`);
  };

  return (
    <>
      <div className="container">
        <Suspense fallback={<RootLoader />}>
          <Outlet />
        </Suspense>
      </div>

      {Config.data.environment !== 'production' && (
        <div className="resetArea">
          {!!widget.date && (
            <button onClick={onClear} className="resetButton">
              Start Over
            </button>
          )}
          {!!auth && (
            <button onClick={onLogout} className="resetButton">
              Log Out
            </button>
          )}
        </div>
      )}

      <div className="build">{Config.data.build}</div>
    </>
  );
};

export default Widget;
